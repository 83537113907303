<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="document_type" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('document_type')">
                            <parameter-selectbox :disabled="formDisable" v-model="innerValue.type"
                                :validate-error="errors[0]" code="document_types"></parameter-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="description" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('description')">
                            <b-form-textarea :disabled="formDisable" v-model="innerValue.not" rows="3"
                                :state="errors[0] ? false : null"></b-form-textarea>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="roles" rules="" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('roles')">
                            <role-selectbox :disabled="formDisable" :multiple="true" valueType="name"
                                v-model="innerValue.roles" :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" v-if="formType === 'create'">
                    <ValidationProvider name="files" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('files')">
                            <b-form-file :disabled="formDisable" v-model="innerValue.files" multiple
                                :placeholder="$t('select_file')" :state="errors[0] ? false : null"
                                ref="fileInput"></b-form-file>
                        </b-form-group>
                        <!-- <b-button variant="outline-secondary" class="mt-3"
                            @click="$refs.fileInput.$el.childNodes[0].click(;">{{
                                $t('browse') }}</b-button> -->
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" class="mb-4" v-if="formType !== 'update'">
                    <h6>{{ $t('files') }}</h6>
                    <template v-for="(fileName, index) in fileNames">
                        <span class="mb-2 mr-1" :key="`file-${index}`">{{ fileName }}, </span>
                    </template>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button :variant="formType == 'delete' ? 'danger' : 'primary'" @click="save">
                        {{ buttonText }}
                    </b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<script>
//Service
import DocumentStorageService from "@/services/DocumentStorageService";

export default {
    name: "DocumentStorageDataForm",
    props: {
        formId: {
            type: Number,
        },
        value: {
            type: Object
        },
        formType: {
            type: String
        }
    },
    data() {
        return {
            form: {
                code: null,
                name: null,
            }
        }
    },
    computed: {
        innerValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
                return val
            },
        },
        buttonText() {
            
            if (this.formType === "create") {
                return this.$t('save')
            }
            else if (this.formType === "show") {
                return this.$t('close')
            }
            else if (this.formType === "update") {
                return this.$t('update')
            }
            else if (this.formType === "delete") {
                return this.$t('delete')
            }

            return this.$t('close')
        },
        fileNames() {
            if (this.innerValue.files?.length > 0) {
                return this.innerValue.files.map(item => {
                    return item.name
                })
            }
            return []
        },
        formDisable() {
            return ['delete', 'show'].includes(this.formType)
        }
    },
    watch: {
        formId: {
            handler(val) {
                if (val > -1) {
                    this.get(val)
                }
            },
            immediate: true
        }
    },
    methods: {
        async save() {
            const valid = await this.$refs.formModalValidate.validate();
            if (!valid) {
                return
            }

            let formData = new FormData();

            if (this.formType === 'create' || this.formType === 'update') {
                if (this.innerValue.not) {
                    formData.append('not', this.innerValue.not);
                }
                formData.append('type', this.innerValue.type);
                this.innerValue.roles.forEach((itm, index) => {
                    formData.append("roles[" + index + "]", itm);
                })
            }

            let response = null
            if (this.formType === 'create') {
                this.innerValue.files.forEach((itm, index) => {
                    formData.append("files[" + index + "]", itm);
                })
                response = await DocumentStorageService.store(formData).catch(e => this.$toast.error(this.$t("api." + e.data.message)))
            }

            else if (this.formType === 'update') {
                response = await DocumentStorageService.store(formData, this.innerValue.id).catch(e => this.$toast.error(this.$t("api." + e.data.message)))
            }
            else if (this.formType === 'delete') {
                const res = await this.$swal({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                if (res.isConfirmed == false) {
                    return
                }
                response = await DocumentStorageService.remove(this.formId).catch(e => this.$toast.error(this.$t("api." + e.data.message)))

            }

            if (response?.data?.success) {
                this.$emit('close')
            }
            this.$toast.success(this.$t("api." + response.data.message))
        },
        get(id) {
            DocumentStorageService.get(id)
                .then((response) => {
                    this.innerValue = response.data.data;
                })
                .catch((error) => {
                    this.showErrors(error)
                })
        },
    }
}
</script>
